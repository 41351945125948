@import "src/static/styles/variables";
@import "src/static/styles/mediaqueries";

.Collapsible {
  max-width: 776px !important;
  margin: 16px auto !important;
  padding: 24px !important;

  @include media(m-device) {
    width: 100% !important;
  }

  :global {
    p {
      font-size: 16px;
      color: $p-color;
      line-height: 24px;
    }
  }
}

.title {
  @include media(m-device) {
    line-height: 42px;
  }
}

.subtitle {
  @include media(m-device) {
    line-height: 34px;
    margin-bottom: 52px;
    top: -7px !important;
  }
}
