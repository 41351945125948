@import "../../static/styles/variables";

.text {
    max-width: 600px;
    word-wrap: break-word;
    margin: 0 auto;

    &__content {
        display: inline;
        width: 100%;
        text-align: left;
        font-family: $baderNarrow;
        color: $light-grey;
        font-size: 18px;
        line-height: 1.5em;

        :global {
            a {
                text-decoration: underline;
                font-family: $baderNarrowBold;
                color: $black;
                font-size: 18px;
            }
        }

        &__showMore {
            text-decoration: underline;
            cursor: pointer;
            font-family: $baderNarrowBold;
            color: $black;
            font-size: 16px;
            display: inline;
        }
    }
}