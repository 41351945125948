@import '../../../../static/styles/variables';

.promocode__banner {
    padding-top: 10px;
    color: #2F1E00;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-family: $baderNarrowBold;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
    }

    .threshold__disclaimer {
        padding-bottom: 15px;
    }

    :global {
        .tooltip__trigger {
            background-color: $primary !important;
            border: none !important;
        }
    }
}
