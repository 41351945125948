@import "../../static/styles/variables";
@import "../../static/styles/mediaqueries";

.noPadding {
  padding: 0 !important;
}

.section {
  max-width: 1088px;
  margin: 0 auto;
  padding: 64px 0;

  @include media(m-device) {
    width: 80%;
  }
}
