@import "../../../../static/styles/variables";
@import "../../../../static/styles/mediaqueries";
@import "../../../../static/styles/buttons";

.blog {
  padding: 80px 20px 100px;

  @include media(m-device) {
    padding: 80px 20px 40px;
  }

  .articles {
    max-width: 1088px;
    margin: 16px auto;

    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @include media(m-device) {
      flex-direction: column;
    }
  }

  .card {
    position: relative;
    flex: 1;
    width: 260px;

    &__image {
      width: 100%;
      height: 195px;
      position: relative;
      border-radius: 6px;
      img {
        border-radius: 6px;
      }

      @include media(m-device) {
        height: auto;
        min-height: 246px;
      }

    }

    & > span {
      border-radius: 4px;
      padding: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @include media(m-device) {
        position: relative;
        left: 0;
        bottom: 0;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 8px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        & > h2 {
          max-height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      & > h2 {
        font-family: $baderNarrow;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        padding-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 60px;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      & > p {
        font-size: 16px;
        font-weight: normal;
        font-family: $baderNarrow;
        background-color: $very-light-grey;
        border-radius: 4px;
        margin-top: 3px;
        padding: 3px 5px;
        width: fit-content;
        user-select: none;
      }
    }

    &__half {
      display: flex;
      flex-direction: column;
      .article {
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 256px;
        transition: all ease-in-out 0.3s;
      }

      & > span {
        height: 100px;
        position: relative;
        left: 0;
        bottom: 0;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 20px 10px 10px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        & > h2 {
          max-height: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      height: 50%;
    }

    @include media(m-device) {
      width: 100%;
      height: 302px;
    }
  }

&__btn_light {
    @extend .btn__light;
    height: 50px;
    width: 220px;
    line-height: 40px;
    margin: 0;

    @include media(m-device) {
      width: 100%;
    }
  }
}

.btn_container {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @include media(m-device) {
    margin-top: 30px;
  }
}

.title {
  @include media(m-device) {
    line-height: 42px;
  }
}

.subtitle {
  @include media(m-device) {
    line-height: 34px;
    margin-bottom: 52px;
    top: -7px !important;
  }
}
