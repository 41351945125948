@import "../../../../static/styles/variables";
@import "../../../../static/styles/mediaqueries";
@import "../../../../static/styles/buttons";


.wrapper {
  background-color: $primary-bg;
  padding: 32px 0;

  :global {
    p {
      line-height: 28px;
      font-size: 20px;
      @include media(m-device) {
        width: 80%;
        text-align: center;
        margin: auto;
      }
    }
  }
}

.themes {
  max-width: 1088px;
  margin: 0 auto 60px auto;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 16px 0 16px;

  @include media(m-device) {
    margin: 0 auto 30px auto;
  }
}

.theme {
  width: calc(25% - 16px);
  margin: 8px;
  display: block;
  height: 195px;
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  @include media(l-device) {
    &:hover {
      & > div {
        transform: scale(1.1);
      }
    }
  }

  @include media(m-device) {
    height: 116px;
    width: calc(50% - 16px);
  }

  @include media(s-device) {
    width: calc(50% - 16px);
  }

  & > div {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    transition: all ease-in-out .3s;
    background-size: cover;
    background-position: center;
    padding: 20px;

    @include media(m-device) {
       padding: 12px;
    }

    & > h2 {
      font-family: $baderNarrow;
      font-size: 22px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      color: $white;

      @include media(m-device) {
        font-size: 16px;
        width: 70%;
        line-height: 23px;
      }
    }
  }


}

.btn_container {
  display: flex;
  justify-content: center;

  .btn_light {
    @extend .btn__light;
    height: 50px;
    width: 220px;
    line-height: 40px;
    margin: 0;
    @include media(m-device) {
    margin: 0;
    width: 80vw;
    }
  }
}