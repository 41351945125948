$primary: #f0b856;
$primary-bg: #F3E7D7;
$secondary: #06D6A0;
$white: #ffffff;
$black: #000000;
$warning: #f00000;
$warning-primary: #f0b857;
$p-color: #666666;
$background-grey: #fafafa;
$background-black: #333333;
$placeholder-color: #999999;
$light-grey: #666666;
$dark-grey: #d8d8d8;
$sheitan: #666;
$very-light-pink: #dcdcdc;
$error-background: #fce02b;
$very-light-grey: #f4f4f4;
$success: #55d234;
$toxic-green: #55d235;
$pinkish-orange: #ff7046;
$varden: #fbedd5;
$silver: #bebebe;

// Fonts :
$baderBoldItalic: 'Bader-bold-italic';
$baderBold: "Bader-bold";
$baderCompressRegular: 'Bader-compress-regular';
$baderCrompress: 'Bader-compress';
$baderRegular: 'Bader-regular';
$baderNarrowBold: 'Bader-narrow-bold';
$baderNarrow: 'Bader-narrow';
$sensa: 'SensaBrush-Fill';
