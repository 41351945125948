@import '../../../../static/styles/variables';
@import '../../../../static/styles/buttons';
@import '../../../../static/styles/mediaqueries';

.container {
    max-width: 1088px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include media(m-device) {
        max-width: 100%;
        margin: 16px;
    }
}