@import "../../../../static/styles/variables";
@import "../../../../static/styles/buttons";
@import "../../../../static/styles/mediaqueries";

.container {
  max-width: 1088px;
  margin: 100px auto;
  margin-bottom: 80px;

  @include media(m-device) {
    max-width: 90%;
    margin: 16px;
  }
}

.guide {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  @include media(m-device) {
    gap: 1em;
    flex-direction: column;
    height: auto;
    margin: auto 0;
    margin-top: 7.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    @include media(m-device) {
      align-items: center;
      gap: 2rem;
    }

    &__title {
      text-align: left;
      margin: 0;
      @include media(m-device) {
        font-size: 48px !important;
      }
    }

    &__subtitle {
      @include media(m-device) {
        line-height: 32px;
        top: -9px !important;
        font-size: 64px !important;
      }
    }

    &__text {
      margin: 0 auto;
    }
  }
}

.text {
  max-width: 600px;
  word-wrap: break-word;
  margin: 0 auto;

  &__content {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-family: $baderNarrow;
    color: $light-grey;
    font-size: 18px;
    line-height: 1.5em;

    :global {
      a {
        text-decoration: underline;
        font-family: $baderNarrowBold;
        color: $black;
        font-size: 18px;
      }
    }

    &__button {
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
      font-family: $baderNarrowBold;
      color: $black;
      font-size: 16px;
    }
  }
}

.btn_container {
  display: flex;
  justify-content: center;

  .btn_light {
    @extend .btn__light;
    height: 50px;
    width: 220px;
    line-height: 40px;
    margin: 0;
    margin-top: 24px;

    @include media(m-device) {
      width: 300px;
      margin-top: 0;
    }
  }
}
