@import './variables';
@import "./mediaqueries";

.btn {
  height: 50px;
  width: max-content;
  box-sizing: content-box;
  font-size: 20px;
  padding: 0 1.25rem;
  color: $white;
  margin: 0 4px;
  transition: all ease-in-out .3s;
  cursor: pointer;
  font-family: $baderCrompress;
  text-transform: uppercase;
  outline: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  vertical-align: middle;
  line-height: 50px;


  &:disabled {
    background-color: $very-light-pink !important;
    cursor: not-allowed;
    pointer-events: none;
  }


  &:hover {
    color: $white;
  }

  &__primary {
    @extend .btn;
    background-color: $primary;

    &:hover {
      background-color: darken($primary, 5%);
    }

    &__sim {
      background-color: darken($toxic-green, 5%);
      &:hover {
        background-color: darken($toxic-green, 10%);
      }
    }
  }

  &__secondary {
    @extend .btn;
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary, 2%);
    }


    &_black {
      @extend .btn__secondary;
      color: $black;
      background-color: $white;
      border: 1px solid $very-light-pink;
      width: 232px;
      height: 50px;
      object-fit: contain;
      padding: 0;
      text-align: center;
      margin-bottom: 20px;
      font-family: $baderCrompress;
      font-size: 20px;
      border-radius: 25px;

      &:hover {
        background-color: $white;
        color: $black;
      }

      img {
        margin-right: 5px;
      }

      @include media(m-device) {
        max-width: 290px;
        margin: auto;
        margin-bottom: 20px;
      }

    }
  }

  &__light {
    @extend .btn;
    background-color: $white;
    border: 1px solid #dcdcdc;
    color: #2F1E00;

    &:hover {
      background-color: darken(white, 2%);
      color: $black;
    }

    &__sim {
      background-color: darken($pinkish-orange, 5%);
      &:hover {
        background-color: darken($pinkish-orange, 10%);
      }
    }
  }

  &__link {
    font-family: $baderNarrow;
    background: transparent;
    outline: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;

    &:active, &:visited, &:hover, &:focus {
      outline: none;
      border: none;
    }

    &__primary {
      @extend .btn__link;
      color: $primary;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
    }

  }

  &__secondary_back {
    @extend .btn;
    color: $black;
    background-color: $white;
    border: 1px solid $very-light-pink;
    width: 232px;
    height: 50px;
    object-fit: contain;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
    font-family: $baderCrompress;
    font-size: 20px;
    border-radius: 25px;

    @include media(m-device) {
      margin: 16px auto;
    }

    &:hover {
      background-color: $white;
      color: $black;
    }

    img {
      margin-right: 5px;
    }

  }

  &__sticky {
    position: sticky;
    bottom: 20px;
  }

  &__disabled {
    @extend .btn;
    background-color: $very-light-pink;
    cursor: not-allowed;
    pointer-events: none;
  }

  &__underline {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
    font-family: $baderNarrowBold;
    font-size: 14px;
    background-color: transparent;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  span {
    margin-top: -2px;
  }

  &__container {
    display: flex;
    justify-content: center;

    @include media(m-device) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a, button {
        margin: 8px auto;
      }
    }
  }
}
